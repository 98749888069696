import axiosInstance from "src/services/axiosInstance";

class BlockService {
  async createBlock(surveyId, payload) {
    return await axiosInstance
      .post(`/surveys/${surveyId}/blocks`, payload)
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async updateBlock(surveyId, blockId, payload) {
    return await axiosInstance
      .patch(`/surveys/${surveyId}/blocks/${blockId}`, payload)
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async removeBlock(surveyId, blockId) {
    return await axiosInstance
      .delete(`/surveys/${surveyId}/blocks/${blockId}`)
      .then(survey => {
        return survey?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async createPageBreak(block_id, payload) {
    return await axiosInstance
      .post(`/blocks/${block_id}/pages`, payload)
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async createAllPageBreak(block_id) {
    return await axiosInstance
      .post(`/blocks/${block_id}/all-page-break`)
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async makeAllQuestionsRequired(block_id, payload) {
    return await axiosInstance
      .post(`/blocks/${block_id}/all-questions-required`, payload)
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async makeAllQuestionsRandom(block_id, payload) {
    return await axiosInstance
      .post(`/blocks/${block_id}/all-questions-random`, payload)
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async removePageBreak(block_id, page_id) {
    return await axiosInstance
      .delete(`/blocks/${block_id}/pages/${page_id}`)
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }
}

export const newblockService = new BlockService();
