import React, {useState} from "react";
import SvgIcon from "@mui/material/SvgIcon";
import Upload01Icon from "@untitled-ui/icons-react/build/esm/Upload01";
import Button from "@mui/material/Button";
import {Dialog, Grid, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {SlideUpTransition} from "src/components/slide-up-transition";
import Typography from "@mui/material/Typography";
import {LoadingButton} from "@mui/lab";
import * as XLSX from "xlsx";
import {useTranslation} from "react-i18next";
import {questionType, questionTypeMap} from "src/schemas/survey/question/question-types";
import {
  multipleChoiceQuestionFormat,
  multipleChoiceQuestionFormatMap,
} from "src/schemas/survey/question/multiple-choice-question-settings";
import {newsurveyService} from "src/new_services/survey/survey";
import {paths} from "src/pages/paths";
import {useRouter} from "src/hooks/use-router";
import {useAuth} from "src/hooks/use-auth";
import {DownloadExcelButton} from "src/pages/admin/surveys/buttons/download-excel-button.js";
import {CreateSurveyButton} from "src/pages/admin/surveys/buttons/create-survey-button";
import {Stack} from "@mui/system";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';



const validateQuestionData = data => {
  const questionData = {};

  const inputQuestionType = questionTypeMap.find(
    el => el.label === data["Question Type"],
  );
  if (!inputQuestionType) {
    return false;
  }
  questionData["questionType"] = inputQuestionType.value;
  if (questionData.questionType === questionType.MultipleChoice) {
    const format = multipleChoiceQuestionFormatMap.find(
      el => el.label === data["Multiple Choice Option Format"],
    );
    questionData["format"] = format ? format.value : multipleChoiceQuestionFormat.list;
    const options = data["Multiple Choice Options"];
    questionData["options"] = options ? options.split("\n") : [];
    questionData["is_options_random"] =
      data["Multiple Choice Options Randomized"] ?? false;
    questionData["allow_multiple_answers"] = data["Allow Multiple Answers"] ?? false;
  }
  questionData["label"] = data["Question Label"] ?? "Unknown Question Label";
  questionData["title"] =
    data["Question Text"].replace(/\n/g, "<br/>") ?? "Unknown Question Title";
  questionData["isRequired"] = data["Required"] ?? false;
  return questionData;
};

const buildQuestion = data => {
  let settings = {};
  if (data.questionType === questionType.MultipleChoice) {
    settings = {
      is_required: data.isRequired,
      format: data.format,
      allow_multiple_answers: data.allow_multiple_answers,
      options: data.options.map(option => ({
        id: null,
        text: option.length === 0 ? "Empty Option" : option,
        is_random: data.is_options_random,
      })),
    };
  } else {
    settings = {
      is_required: data.isRequired,
    };
  }
  return {
    id: null,
    label: data.label,
    text: data.title,
    type: data.questionType,
    is_random: false,
    ...settings,
  };
};

export const ImportExcelButton = () => {
  const {t, i18n} = useTranslation();
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [surveyName, setSurveyName] = useState("");
  const [blocks, setBlocks] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const auth = useAuth();
  const [uploadMessage, setUploadMessage] = useState("");
  const theme = useTheme(); // Initialize theme
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Define isMobile



  const handleClickUploadExcelFile = e => {
    const file = e.target.files[0];
    setUploadMessage(`${file.name} has been uploaded.`); // Set the upload message
    const reader = new FileReader();
    //setSurveyName(file.name.replace(".xlsx", ""));
    {/* TODO: Ahmet: Bunu kapattim anket isminin direkt yuklenen dosyadan gelmesini istemiyoruz. 
              Onun yerine bu dosya yuklenmistir diye not cikarabiliriz. */}

    reader.onload = event => {
      const data = event.target.result;
      const workbook = XLSX.read(data, {type: "binary"});

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(sheet);
        // Process the JSON data as needed
      console.log(jsonData);  // Example: just log it
      setBlocks(jsonData);  // Assuming setBlocks is to store processed data
      if (Array.isArray(jsonData)) {
        const groupedData = jsonData.reduce((acc, obj) => {
          const key = obj["Question Group Name"];
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});

        const invalidQuestions = [];
        const result = Object.entries(groupedData).map(
          ([blockName, questions], block_index) => {
            const blockQuestions = questions.reduce((acc, obj, index) => {
              const validatedQuestionData = validateQuestionData(obj);
              if (validatedQuestionData) {
                const question = buildQuestion(validatedQuestionData);
                acc.push(question);
              } else {
                invalidQuestions.push(index + 1);
              }
              return acc;
            }, []);

            return {
              title: blockName,
              pages: [
                {
                  id: null,
                  questions: blockQuestions,
                },
              ],
            };
          },
        );
        if (invalidQuestions.length) {
          const invalidQuestionRows = invalidQuestions.join(", ");
          auth.showAlert(
            "error",
            `There are invalid questions in row(s) bypassed. Index: ${invalidQuestionRows}.`,
          );
        }
        console.log(result);
        setBlocks(result);
      }
    };
    reader.readAsBinaryString(file);
  };

  const importFile = () => {
    setIsImporting(true);
    const surveyData = {
      title: surveyName,
      blocks: blocks,
    };
    newsurveyService.createFromExcel(surveyData).then(survey => {
      router.push(paths.surveyBuilder.edit.replace(":uuid", survey.id));
      setIsImporting(false);
    });
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={
          <SvgIcon>
            <Upload01Icon />
          </SvgIcon>
        }
        variant="contained"
        sx={{direction: i18n.language === "ar" ? "rtl" : "ltr"}}
      >
        {t("importExcelButtonLabel")}
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={SlideUpTransition}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            flex: "1 1 auto",
          }}
        >
          <Container
            maxWidth="sm"
            sx={{
              py: {
                xs: "60px",
                md: "120px",
              },
              direction: i18n.language === "ar" ? "rtl" : "ltr",
            }}
          >
            <Typography align="center" variant="h4">
              {t("importExcelDialogTitle")}
            </Typography>
            
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth="md">
          <Stack spacing={4} alignItems="center">
            {/* Step Instructions */}
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>Find Participants</Typography>
            <Stack direction={isMobile ? "column" : "row"} spacing={4} alignItems="center" justifyContent="center">
              <Box textAlign="center">
                <Typography variant="h6">Step 1</Typography>
                <Typography variant="body1">Download Excel Format</Typography>
              </Box>
              <Box textAlign="center">
                <Typography variant="h6">Step 2</Typography>
                <Typography variant="body1">Write or Paste your questions in excel file</Typography>
              </Box>
              <Box textAlign="center">
                <Typography variant="h6">Step 3</Typography>
                <Typography variant="body1">Upload your survey questions directly to the platform from Excel</Typography>
              </Box>
            </Stack>
            </Stack>
            </Container >
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={10}>
            <Typography align="center" variant="h10" >
              ----
              <br/>
            </Typography>
  
  <Typography align="left" variant="h10" sx={{ marginRight: 2 }}>
    {t("You can download the draft excel to copy paste your own questions. You can manage question and blok settings from this excel, such as required, randomization, multiple answer permision.")}
  </Typography>
  
  
  </Grid>
  </Box>
  <Typography align="center">
  <DownloadExcelButton />
  </Typography>
  <Typography align="left" variant="h10" sx={{ marginRight: 2 }}>
    {t("Please define your survey name and upload your edited excel file.")}
  </Typography>
  

            
  <Typography align="center">
 
            <Grid containerspacing={3}>
              <Grid item xs={8}>
                <TextField
                  sx={{mt: 1}}
                  fullWidth
                  label={t("importExcelNameLabel")}
                  value={surveyName}
                  onChange={e => setSurveyName(e.target.value)}
                />
              </Grid>
              
            </Grid>
            <Grid item xs={4}>
                <LoadingButton
                  fullWidth
                  //sx={{mt: 3, height: "52px"}}
                  loading={isUploading}
                  onClick={() => setIsUploading(true)}
                  onBlur={() => setIsUploading(false)}
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    width: "250px", // Adjust the width as desired
                    marginBottom: i18n.language === "ar" ? "2rem" : "3rem"}}
                  component="label"
                >
                  <SvgIcon>
                    <Upload01Icon />
                  </SvgIcon>
                  <Typography align="center" variant="h10">
                    {t("buttons.UploadDraftExcel")}
                    </Typography>
              
            
                  <input
                    type="file"
                    accept=".xlsx, .xls"
                    style={{display: "none"}}
                    onChange={handleClickUploadExcelFile}
                  />
                </LoadingButton>
              </Grid>
              </Typography>

            {/* Display the upload message */}
{uploadMessage && (
  <Typography align="center" variant="body1" sx={{ mt: 2, color: 'green' }}>
    {uploadMessage}
  </Typography>
)}
            <LoadingButton
              fullWidth
              loading={isImporting}
              disabled={!surveyName || !blocks}
              sx={{mt: 3}}
              size="large"
              type="submit"
              variant="contained"
              onClick={importFile}
            >
              {t("importFileButtonLabel")}
            </LoadingButton>
            <Button fullWidth sx={{mt: 2}} onClick={() => setOpen(false)}>
              {t("cancelButtonLabel")}
            </Button>
          
         
      </Container>
        </Box>
      </Dialog>
    </>
  );
};
