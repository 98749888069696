import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export const SplashScreen = props => {
  const {positionFixed = false} = props;

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "background.paper",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        left: 0,
        p: 3,
        position: positionFixed ? "fixed" : "",
        top: 0,
        width: "100vw",
        zIndex: 1400,
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
        }}
      >
        <img
          className="logo"
          src={"/logo.png"}
          alt="Galata Anket logo"
          width="213"
          height="150"
        />
      </Box>
      <CircularProgress sx={{mt: 4}} />
    </Box>
  );
};
