import {createComponents} from "src/theme/light/components";
import {createPalette} from "src/theme/light/palette";

export const createLightOptions = () => {
  const palette = createPalette();
  const components = createComponents({palette});

  return {
    components,
    palette,
  };
};
