import axiosInstance from "src/services/axiosInstance";

class PageService {
  async createQuestion(payload) {
    return await axiosInstance
      .post(`/pages/question`, payload)
      .then(pageQuestions => {
        return pageQuestions?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async copyQuestion(pageId, questionId) {
    return await axiosInstance
      .post(`/pages/${pageId}/questions/${questionId}/copy`)
      .then(questions => {
        return questions?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async removeQuestion(pageId, questionId) {
    return await axiosInstance
      .delete(`/pages/${pageId}/questions/${questionId}`)
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }
}

export const newpageService = new PageService();
