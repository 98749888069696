import {Outlet} from "react-router-dom";
import {Suspense} from "react";
import {AuthLayout} from "src/layouts/auth/auth-layout";
import {Login} from "src/pages/auth/login";
import {ResetPassword} from "src/pages/auth/reset-password";

export const authRoutes = [
  {
    path: "auth",
    children: [
      {
        element: (
          <AuthLayout>
            <Suspense>
              <Outlet />
            </Suspense>
          </AuthLayout>
        ),
        children: [
          {
            path: "login",
            element: <Login />,
          },
          {
            path: "reset-password",
            element: <ResetPassword />,
          },
        ],
      },
    ],
  },
];
