import {createContext, useEffect, useState} from "react";
import {newsurveyService} from "src/new_services/survey/survey";
import {useParams} from "react-router-dom";
import {questionModelFactory} from "src/contexts/utils/question-model-factory";
import {newquestionService} from "src/new_services/survey/question";
import {newpageService} from "src/new_services/survey/page";
import {newblockService} from "src/new_services/survey/block";
import {newflowService} from "src/new_services/survey/flow";

export const SurveyBuilderContext = createContext({
  survey: null,
  blocks: null,
  pages: null,
  questions: null,

  surveyFlow: null,

  currentBlock: null,
  currentQuestion: null,
  getCurrentQuestion: _ => {},
  handleSetCurrentBlock: block => {},
  handleSetCurrentQuestion: question => {},

  // QUESTIONS
  listQuestions: (pageId, blockId) => {},
});

export const SurveyBuilderProvider = props => {
  const {children} = props;
  const {uuid} = useParams();
  const [currentBlock, setCurrentBlock] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [blocks, setBlocks] = useState(null);
  const [pages, setPages] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [surveyFlow, setSurveyFlow] = useState(null);
  const [surveySettings, setSurveySettings] = useState(null);

  const buildQuestion = (blockId, pageId, question) => {
    return {
      blockId: blockId,
      pageId: pageId,
      id: question.id,
      label: question.label,
      text: question.text,
      type: question.type,
      index: question.index,
      is_random: question.is_random,
      display_logics: question.display_logics,
      skip_logics: question.skip_logics,
      ...questionModelFactory(question),
    };
  };

  const handleUpdateSurveySettings = async data => {
    await newsurveyService.updateSurveySettings(survey.id, data).then(updatedSurvey => {
      setSurveyDetails(updatedSurvey);
      setSurveySettings(updatedSurvey?.settings);
    });
  };

  const setSurveyDetails = retrievedSurvey => {
    const tempBlocks = {};
    const tempPages = {};
    const tempQuestions = {};

    setSurvey({
      id: retrievedSurvey.id,
      title: retrievedSurvey.title,
      description: retrievedSurvey.description,
      flow_id: retrievedSurvey.flow_id,
    });
    retrievedSurvey.blocks.forEach(block => {
      tempBlocks[block.id] = {
        id: block.id,
        title: block.title,
        index: block.index,
      };

      block.pages.forEach(page => {
        tempPages[page.id] = {
          id: page.id,
          index: page.index,
          blockId: block.id,
        };

        page.questions.forEach(question => {
          tempQuestions[question.id] = buildQuestion(block.id, page.id, question);
        });
      });
    });
    setCurrentBlock(Object.values(tempBlocks)[0]);
    setCurrentQuestion(Object.values(tempQuestions)[0]);

    setBlocks(tempBlocks);
    setPages(tempPages);
    setQuestions(tempQuestions);
  };

  useEffect(() => {
    newsurveyService.retrieveSurvey(uuid).then(retrievedSurvey => {
      setSurveyDetails(retrievedSurvey);
      setSurveySettings(retrievedSurvey.settings);
    });
  }, []);

  const handleSetCurrentQuestion = question => {
    setCurrentBlock(blocks[question.blockId]);
    setCurrentQuestion(question);
  };

  const handleSetCurrentBlock = block => {
    setCurrentQuestion(null);
    setCurrentBlock(block);
  };

  const getCurrentQuestion = _ => {
    return questions[currentQuestion.id];
  };

  const listBlockPages = _ => {
    return Object.values(pages).filter(p => p.blockId === currentBlock.id);
  };

  /* BLOCK */
  const handleCreateBlock = blockTitle => {
    const blockIndex = Object.keys(blocks).length;
    const data = {
      title: blockTitle,
      index: blockIndex,
    };
    newblockService.createBlock(survey.id, data).then(block => {
      setBlocks(prevData => ({
        ...prevData,
        [block.id]: {
          id: block.id,
          title: block.title,
          index: blockIndex,
        },
      }));
    });
  };

  const handleUpdateBlock = async payload => {
    await newblockService.updateBlock(survey.id, currentBlock.id, payload);
    setBlocks(prevData => ({
      ...prevData,
      [currentBlock.id]: {
        ...currentBlock,
        ...payload,
      },
    }));
  };

  const handleRemoveBlock = blockId => {
    newblockService.removeBlock(survey.id, blockId).then(data => {
      setSurveyDetails(data);
    });
  };

  const handleCreateAllPageBreak = async _ => {
    const blockId = currentBlock.id;
    const tempPages = {...pages};
    const tempQuestions = {...questions};
    await newblockService.createAllPageBreak(blockId).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        tempPages[page.id] = {
          id: page.id,
          index: page.index,
          blockId: updatedBlock.id,
        };

        page.questions.forEach(question => {
          tempQuestions[question.id] = buildQuestion(updatedBlock.id, page.id, question);
        });
      });
      if (updatedBlock.pages.length === 1) {
        const pagesToDelete = Object.values(pages).filter(
          page => page.blockId === blockId && page.id !== updatedBlock.pages[0].id,
        );
        pagesToDelete.forEach(page => {
          delete tempPages[page.id];
        });
      }

      setPages(tempPages);
      setQuestions(tempQuestions);
    });
  };

  const handleAllQuestionsRequired = async data => {
    const blockId = currentBlock.id;
    const tempQuestions = {...questions};
    await newblockService.makeAllQuestionsRequired(blockId, data).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        page.questions.forEach(question => {
          tempQuestions[question.id] = buildQuestion(updatedBlock.id, page.id, question);
        });
      });
      setQuestions(tempQuestions);
    });
  };

  const handleAllQuestionsRandom = async data => {
    const blockId = currentBlock.id;
    const tempQuestions = {...questions};
    await newblockService.makeAllQuestionsRandom(blockId, data).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        page.questions.forEach(question => {
          tempQuestions[question.id] = buildQuestion(updatedBlock.id, page.id, question);
        });
      });
      setQuestions(tempQuestions);
    });
  };

  const handleCreatePageBreak = async questionId => {
    const blockId = questions[questionId].blockId;
    const targetPageId = questions[questionId].pageId;
    const data = {
      page_id: targetPageId,
      question_id: questionId,
    };
    const tempPages = {...pages};
    const tempQuestions = {...questions};
    await newblockService.createPageBreak(blockId, data).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        tempPages[page.id] = {
          id: page.id,
          index: page.index,
          blockId: updatedBlock.id,
        };

        page.questions.forEach(question => {
          tempQuestions[question.id] = buildQuestion(updatedBlock.id, page.id, question);
        });
      });

      setPages(tempPages);
      setQuestions(tempQuestions);
    });
  };

  const handleRemovePageBreak = async pageId => {
    const blockId = pages[pageId].blockId;
    const deletedPageIndex = pages[pageId].index + 1;
    const deletedPage = Object.values(pages).find(
      page => page.index === deletedPageIndex,
    );
    const tempPages = {...pages};
    const tempQuestions = {...questions};
    await newblockService.removePageBreak(blockId, pageId).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        tempPages[page.id] = {
          id: page.id,
          index: page.index,
          blockId: updatedBlock.id,
        };

        page.questions.forEach(question => {
          tempQuestions[question.id] = buildQuestion(updatedBlock.id, page.id, question);
        });
      });
      delete tempPages[deletedPage.id];

      setPages(tempPages);
      setQuestions(tempQuestions);
    });
  };

  // QUESTION
  const handleCreateQuestion = payload => {
    const tempQuestions = {};
    newpageService.createQuestion(payload).then(pageQuestions => {
      pageQuestions.forEach(question => {
        tempQuestions[question.id] = buildQuestion(
          payload.block_id,
          question.page_id,
          question,
        );
      });
      if (!payload.page_id) {
        setPages(prevData => ({
          ...prevData,
          [pageQuestions[0].page_id]: {
            id: pageQuestions[0].page_id,
            index: 0,
            blockId: payload.block_id,
          },
        }));
      }
      setQuestions(prevData => ({
        ...prevData,
        ...tempQuestions,
      }));
    });
  };

  const listQuestions = (pageId, blockId) => {
    if (pageId) {
      return Object.values(questions)
        .filter(question => question.pageId === pageId)
        .sort((a, b) => a.index - b.index);
    } else if (blockId) {
      return Object.values(questions).filter(question => question.blockId === blockId);
    }
    return Object.values(questions).sort((a, b) => a.index - b.index);
  };

  const handleUpdateQuestion = (questionId, payload) => {
    newquestionService.updateQuestion(questionId, payload).then(question => {
      setQuestions(prevData => ({
        ...prevData,
        [questionId]: {
          ...questions[questionId],
          ...question,
        },
      }));
    });
  };

  const handleCreateDisplayLogicQuestion = (questionId, payload) => {
    newquestionService.createQuestionDisplayLogic(questionId, payload).then(question => {
      setQuestions(prevData => ({
        ...prevData,
        [questionId]: {
          ...questions[questionId],
          ...question,
        },
      }));
    });
  };

  const handleUpdateQuestionType = (questionId, newQuestionType) => {
    const payload = {
      new_type: newQuestionType,
    };
    const blockId = questions[questionId].blockId;
    const pageId = questions[questionId].pageId;
    newquestionService.updateQuestionType(questionId, payload).then(question => {
      const newQuestions = {
        ...questions,
        [question.id]: {blockId: blockId, pageId: pageId, ...question},
      };
      delete newQuestions[questionId];
      setQuestions(newQuestions);
      setCurrentQuestion(question);
    });
  };

  const handleCopyQuestion = questionId => {
    const tempQuestions = {};
    const pageId = questions[questionId].pageId;
    const blockId = questions[questionId].blockId;
    newpageService.copyQuestion(pageId, questionId).then(pageQuestions => {
      pageQuestions.forEach(question => {
        tempQuestions[question.id] = {
          pageId: pageId,
          blockId: blockId,
          ...question,
        };
      });
      setQuestions(prevData => ({
        ...prevData,
        ...tempQuestions,
      }));
    });
  };

  const handleRemoveQuestion = questionId => {
    const pageId = questions[questionId].pageId;

    const tempPages = {...pages};
    const tempQuestions = {...questions};
    newpageService.removeQuestion(pageId, questionId).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        tempPages[page.id] = {
          id: page.id,
          index: page.index,
          blockId: updatedBlock.id,
        };

        page.questions.forEach(question => {
          tempQuestions[question.id] = buildQuestion(updatedBlock.id, page.id, question);
        });
      });

      if (!updatedBlock.pages.find(page => page.id === pageId)) {
        delete tempPages[pageId];
      }
      delete tempQuestions[questionId];
      setCurrentQuestion(null);

      setPages(tempPages);
      setQuestions(tempQuestions);
    });
  };

  // MULTIPLE_CHOICE QUESTION
  const handleCreateMultipleChoiceQuestionOptions = questionId => {
    newquestionService.createMultipleChoiceQuestionOptions(questionId).then(question => {
      setQuestions(prevData => ({
        ...prevData,
        [questionId]: {
          ...questions[questionId],
          options: question.options,
        },
      }));
    });
  };

  const handleUpdateMultipleChoiceQuestionOptions = (questionId, options) => {
    newquestionService
      .updateMultipleChoiceQuestionOptions(questionId, options)
      .then(question => {
        setQuestions(prevData => ({
          ...prevData,
          [questionId]: {
            ...questions[questionId],
            options: question.options,
          },
        }));
      });
  };

  const handleDeleteMultipleChoiceQuestionOptions = (questionId, optionId) => {
    newquestionService
      .deleteMultipleChoiceQuestionOptions(questionId, optionId)
      .then(question => {
        setQuestions(prevData => ({
          ...prevData,
          [questionId]: {
            ...questions[questionId],
            options: question.options,
            display_logics: question.display_logics, // FIXME: update actual question display_logics
            skip_logics: question.skip_logics,
          },
        }));
      });
  };

  const handleCreateQuestionSkipLogic = (questionId, payload) => {
    newquestionService.createQuestionSkipLogic(questionId, payload).then(question => {
      setQuestions(prevData => ({
        ...prevData,
        [questionId]: {
          ...questions[questionId],
          skip_logics: question.skip_logics,
        },
      }));
    });
  };

  const handleRemoveQuestionSkipLogic = (questionId, skipLogicId) => {
    newquestionService.deleteQuestionSkipLogic(questionId, skipLogicId).then(question => {
      setQuestions(prevData => ({
        ...prevData,
        [questionId]: {
          ...questions[questionId],
          skip_logics: question.skip_logics,
        },
      }));
    });
  };

  const handleDeleteMultipleChoiceQuestionCarryChoiceOptions = questionId => {
    newquestionService
      .deleteMultipleChoiceQuestionCarryOptions(questionId)
      .then(question => {
        setQuestions(prevData => ({
          ...prevData,
          [questionId]: {
            ...questions[questionId],
            options: question.options,
          },
        }));
      });
  };

  // FLOW
  const handleFetchSurveyFlow = () => {
    newflowService.retrieveSurveyFlow(survey.id).then(surveyFlow => {
      setSurveyFlow(surveyFlow);
    });
  };

  const handleCreateFlowElement = (flowId, data) => {
    newflowService.createFlowElement(survey.id, flowId, data).then(surveyFlow => {
      setSurveyFlow(surveyFlow);
    });
  };

  const handleDragAndDropFlowElements = data => {
    newflowService.dragAndDropElement(survey.id, data).then(surveyFlow => {
      setSurveyFlow(surveyFlow);
    });
  };

  const handleDeleteFlowElement = (flowId, elementId) => {
    newflowService.deleteFlowElement(survey.id, flowId, elementId).then(surveyFlow => {
      setSurveyFlow(surveyFlow);
    });
  };

  const handleUpdateBranchCondition = (elementId, data) => {
    newflowService.updateBranchCondition(survey.id, elementId, data).then(surveyFlow => {
      setSurveyFlow(surveyFlow);
    });
  };

  return (
    <SurveyBuilderContext.Provider
      value={{
        survey,
        surveySettings,
        blocks,
        pages,

        surveyFlow,

        currentBlock,
        currentQuestion,
        getCurrentQuestion,
        listBlockPages,
        handleSetCurrentBlock,
        handleSetCurrentQuestion,

        // SURVEY
        handleUpdateSurveySettings,
        handleAllQuestionsRequired,

        // BLOCK
        handleCreateBlock,
        handleUpdateBlock,
        handleRemoveBlock,
        handleCreatePageBreak,
        handleCreateAllPageBreak,
        handleAllQuestionsRandom,
        handleRemovePageBreak,

        // QUESTION
        listQuestions,
        handleCreateQuestion,
        handleUpdateQuestion,
        handleUpdateQuestionType,
        handleCopyQuestion,
        handleRemoveQuestion,
        handleCreateDisplayLogicQuestion,
        handleCreateMultipleChoiceQuestionOptions,
        handleDeleteMultipleChoiceQuestionCarryChoiceOptions,
        handleUpdateMultipleChoiceQuestionOptions,
        handleDeleteMultipleChoiceQuestionOptions,
        handleCreateQuestionSkipLogic,
        handleRemoveQuestionSkipLogic,

        // FLOW
        handleFetchSurveyFlow,
        handleCreateFlowElement,
        handleDeleteFlowElement,
        handleUpdateBranchCondition,
        handleDragAndDropFlowElements,
      }}
    >
      {children}
    </SurveyBuilderContext.Provider>
  );
};

export const SurveyBuilderConsumer = SurveyBuilderContext.Consumer;
