import {createContext, useEffect, useState} from "react";
import GeneralAlert from "src/components/general-alert";
import {newauthService} from "src/new_services/auth";
import {useNavigate} from "react-router-dom";
import {paths} from "src/pages/paths";
import {ACCESS_TOKEN_KEY} from "src/services/axiosInstance";

const initialState = {
  token: localStorage.getItem(ACCESS_TOKEN_KEY),
  isInitialized: true,
  isAuthenticated: false,
  user: null,
};

export const AuthContext = createContext({
  state: initialState,
});

export const AuthProvider = props => {
  const {children} = props;
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [alert, setAlert] = useState({
    open: false,
    type: "success",
    message: "",
  });

  useEffect(() => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (token) {
      newauthService.me().then(response => {
        if (response) {
          setState({
            isInitialized: true,
            isAuthenticated: true,
            user: response,
            token: token,
          });
        } else {
          setState({
            isInitialized: true,
            isAuthenticated: false,
            user: null,
            token: false,
          });
          signOut();
        }
      });
    }
  }, []);

  const signIn = async (email, password) => {
    return await newauthService.signIn(email, password).then(response => {
      return response
        ? setState({
            isInitialized: true,
            isAuthenticated: true,
            user: response.user,
            token: response?.access,
          })
        : showAlert("error", "Email or password is incorrect, Please try again.");
    });
  };
  const register = async emailOrPhone => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^05\d{9}$/;

    const data = {};
    if (emailRegex.test(emailOrPhone)) {
      data["email"] = emailOrPhone;
    } else if (phoneRegex.test(emailOrPhone)) {
      data["phone"] = emailOrPhone;
    }

    return await newauthService.register(data);
  };
  const resetPassword = async emailOrPhone => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^05\d{9}$/;

    const data = {};
    if (emailRegex.test(emailOrPhone)) {
      data["email"] = emailOrPhone;
    } else if (phoneRegex.test(emailOrPhone)) {
      data["phone"] = emailOrPhone;
    }
    return await newauthService.resetPassword(data);
  };

  const signOut = async () => {
    return await newauthService.signOut().then(_ => {
      setState({
        isInitialized: true,
        isAuthenticated: false,
        user: null,
        token: false,
      });
      navigate(paths.auth.login);
    });
  };

  const showAlert = (type, message) => {
    setAlert({
      open: true,
      type,
      message,
    });
  };

  const closeAlert = () => {
    setAlert(prevState => ({
      ...prevState,
      open: false,
    }));
  };

  return (
    <AuthContext.Provider
      value={{
        state,

        signIn,
        register,
        signOut,
        resetPassword,

        showAlert,
      }}
    >
      {children}
      <GeneralAlert
        open={alert.open}
        onClose={closeAlert}
        type={alert.type}
        message={alert.message}
      />
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
