import {useTranslation} from "react-i18next";
import User01Icon from "@untitled-ui/icons-react/build/esm/User01";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import SvgIcon from "@mui/material/SvgIcon";
import {usePopover} from "src/hooks/use-popover";
import {
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Settings04Icon from "@untitled-ui/icons-react/build/esm/Settings04";
import Button from "@mui/material/Button";
import {useCallback} from "react";
import {useAuth} from "src/hooks/use-auth";
import {RouterLink} from "src/components/router-link";

export const AccountButton = () => {
  const {t} = useTranslation();
  const popover = usePopover();
  const auth = useAuth();

  const userMail = auth?.state?.user?.email || auth?.state?.user?.data?.email;

  const handleLogout = useCallback(async () => {
    try {
      popover.handleClose();

      await auth.signOut();
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "divider",
          height: 40,
          width: 40,
          borderRadius: "50%",
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32,
          }}
          src={"/assets/avatars/avatar-anika-visser.png"}
        >
          <SvgIcon>
            <User01Icon />
          </SvgIcon>
        </Avatar>
      </Box>
      <Popover
        anchorEl={popover.anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        disableScrollLock
        onClose={popover.handleClose}
        open={!!popover.open}
        PaperProps={{sx: {width: 200}}}
      >
        <Box sx={{p: 2}}>
          <Typography color="text.secondary" variant="body2">
            {userMail}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{p: 1}}>
          <ListItemButton
             component={RouterLink}
             href={"settingcategory"}
            onClick={popover.handleClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <Settings04Icon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1"> {t("settingcategory")}</Typography>}
            />
          </ListItemButton>
        </Box>
        <Divider sx={{my: "0 !important"}} />
        <Box
          sx={{
            display: "flex",
            p: 1,
            justifyContent: "center",
          }}
        >
          <Button color="inherit" onClick={handleLogout} size="small">
            {t("LogoutButton")}
          </Button>
        </Box>
      </Popover>
    </>
  );
};
