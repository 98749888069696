import {MultipleChoiceQuestionOptions} from "src/pages/new-survey/question-factory/multiple-choice-question/options";

export const MultipleChoiceQuestion = props => {
  const {question} = props;

  return (
    <>
      <MultipleChoiceQuestionOptions question={question} />
    </>
  );
};
