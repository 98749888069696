import i18n from "i18next";
import {enSurveyTranslations} from "src/locales/survey-translations/en";
import {trSurveyTranslations} from "src/locales/survey-translations/tr";
import {arSurveyTranslations} from "src/locales/survey-translations/ar";

const surveyI18n = i18n.createInstance();

surveyI18n.init({
  lng: "en", // Set the default language
  resources: {
    en: {
      translation: enSurveyTranslations,
    },
    tr: {
      translation: trSurveyTranslations,
    },
    ar: {
      translation: arSurveyTranslations,
    },
  },
});

export default surveyI18n;
