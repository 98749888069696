import {Outlet} from "react-router-dom";
import {Suspense} from "react";
import {AuthLayout} from "src/layouts/auth/auth-layout";
import {UserActivation} from "src/pages/action/user-activation";
import {UserNotification} from "src/pages/action/user-notification";
import {UserSetPassword} from "src/pages/action/user-set-password";

export const actionRoutes = [
  {
    path: "action",
    children: [
      {
        element: (
          <AuthLayout>
            <Suspense>
              <Outlet />
            </Suspense>
          </AuthLayout>
        ),
        children: [
          {
            path: "activate",
            children: [
              {
                path: ":activationToken",
                element: <UserActivation />,
              },
            ],
          },
          {
            path: "notification",
            children: [
              {
                path: ":notificationToken",
                element: <UserNotification />,
              },
            ],
          },
          {
            path: "set-password",
            children: [
              {
                path: ":setPasswordToken",
                element: <UserSetPassword />,
              },
            ],
          },
        ],
      },
    ],
  },
];
