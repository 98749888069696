import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import Download01Icon from "@untitled-ui/icons-react/build/esm/Download01";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";

export const DownloadExcelButton = () => {
  const {t, i18n} = useTranslation();

  const handleClickDownloadExcelFile = () => {
    const link = document.createElement("a");
    link.href = "/files/GA_Anket_Soru_Taslagi-Sorular.xlsx"; // Path to your Excel file
    link.setAttribute("download", "GA_Anket_Soru_Taslagi-Sorular.xlsx"); // Name of the file when downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Button
        onClick={() => handleClickDownloadExcelFile()}
        startIcon={
          <SvgIcon>
            <Download01Icon />
          </SvgIcon>
        }
        variant="contained"
        sx={{
          width: "250px", // Adjust the width as desired
          marginBottom: i18n.language === "ar" ? "2rem" : "3rem"}}
      >
        {t("buttons.downloadDraftExcel")}
      </Button>
      
    </>
  );
};


