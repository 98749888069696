import {createComponents} from "src/theme/base/components";
import {createTypography} from "src/theme/base/typography";
import {createShadows} from "src/theme/base/shadows";

export const createBaseOptions = () => {
  return {
    shadows: createShadows(),
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
      },
    },
    components: createComponents(),
    direction: "ltr",
    shape: {
      borderRadius: 8,
    },
    typography: createTypography(),
  };
};
