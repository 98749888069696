import {SurveyBuilderLayout} from "src/layouts/survey-builder/survey-builder-layout";
import {Outlet} from "react-router-dom";
import {lazy, Suspense} from "react";

const SurveyEditorPage = lazy(
  () => import("src/pages/admin/survey-builder/survey-editor"),
);
const SurveyPreviewPage = lazy(
  () => import("src/pages/admin/survey-builder/survey-preview"),
);
const SurveyDistributionsPage = lazy(
  () => import("src/pages/admin/survey-builder/survey-distributions"),
);
const SurveyResultsPage = lazy(
  () => import("src/pages/admin/survey-builder/survey-results"),
);
const SurveyAnalyticsDashboardPage = lazy(
  () => import("src/pages/admin/survey-builder/survey-analytics-dashboard"),
);

export const surveyBuilderRoutes = [
  {
    path: "survey-builder",
    element: (
      <SurveyBuilderLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </SurveyBuilderLayout>
    ),
    children: [
      {
        path: ":uuid",
        children: [
          {
            path: "edit",
            element: <SurveyEditorPage />,
          },
          {
            path: "preview",
            element: <SurveyPreviewPage />,
          },
          {
            path: "distributions",
            element: <SurveyDistributionsPage />,
          },
          {
            path: "results",
            element: <SurveyResultsPage />,
          },
          {
            path: "analytics",
            element: <SurveyAnalyticsDashboardPage />,
          },
        ],
      },
    ],
  },
];
