import styled from "@emotion/styled";

const DashboardContainer = styled.div(_ => ({
  // display: "flex",
  // flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",

  height: "100vh",
  overflow: "auto",
}));

export const SurveyEngineLayout = props => {
  const {children} = props;

  return <DashboardContainer>{children}</DashboardContainer>;
};
