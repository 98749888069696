export const arSurveyTranslations = {
  survey: "استطلاع",
  next: "التالي",
  prev: "السابق",
  pageErrors: "هناك أخطاء. يرجى التحقق من إجاباتك.",
  requiredQuestionError: "يجب الرد على هذا السؤال.",
  integerInputTypeError: "الرجاء إدخال رقم.",
  copySurveyURL: "نسخ رابط الاستطلاع",
  copySurveyURLSuccessful: "تم نسخ رابط الاستطلاع، يمكنك مشاركته الآن.",
  other: "أخرى",
  otherOptionEmptyError: "لا يمكن ترك الخيار فارغًا.",
};
