import {questionType} from "src/schemas/survey/question/question-types";

function checkMultipleChoiceQuestion(expression, surveyQuestions, surveyAnswers) {
  const answer = surveyAnswers[expression.left_operand];
  if (answer) {
    const targetQuestion = surveyQuestions[expression.left_operand];
    const rightOperand = targetQuestion.options.find(
      option => option.id === expression.right_operand,
    );
    if (targetQuestion.allow_multiple_answers) {
      if (answer.text.split("---").includes(rightOperand.text)) {
        return true;
      }
    } else {
      if (answer.text === rightOperand.text) {
        return true;
      }
    }
  }
  return false;
}

function checkShortTextQuestion(expression, surveyQuestions, surveyAnswers) {
  const answer = surveyAnswers[expression.left_operand];
  if (answer) {
    if (answer.text === expression.right_operand) {
      return true;
    }
  }
  return false;
}

export function displayLogic(question, surveyQuestions, surveyAnswers) {
  if (question.display_logics.length === 0) {
    return true;
  }
  if (question.display_logics[0].expressions.length === 0) {
    return true;
  }
  const expression = question.display_logics[0].expressions[0];
  switch (expression.locator_type) {
    case questionType.MultipleChoice:
      return checkMultipleChoiceQuestion(expression, surveyQuestions, surveyAnswers);
    case questionType.ShortText:
      return checkShortTextQuestion(expression, surveyQuestions, surveyAnswers);
    case "embedded_data":
      const queryParameters = new URLSearchParams(window.location.search);
      const parameters = {};
      for (const [key, value] of queryParameters.entries()) {
        parameters[key] = value;
      }
      switch (expression.operand) {
        case "is_equal":
          return parameters[expression.left_operand] === expression.right_operand;
        case "is_not_equal":
          return parameters[expression.left_operand] !== expression.right_operand;
        default:
          return false;
      }
    default:
      return true;
  }
}

export function checkSkipLogic(question, surveyQuestions, surveyAnswers) {
  if (question.skip_logics.length === 0) {
    return false;
  }
  if (question.type === questionType.MultipleChoice) {
    for (const skip_logic of question.skip_logics) {
      if (
        checkMultipleChoiceQuestion(skip_logic.locator, surveyQuestions, surveyAnswers)
      ) {
        return skip_logic;
      }
    }
  } else if (question.type === questionType.ShortText) {
    for (const skip_logic of question.skip_logics) {
      if (checkShortTextQuestion(skip_logic.locator, surveyQuestions, surveyAnswers)) {
        return skip_logic;
      }
    }
  }
  return false;
}
