import Box from "@mui/material/Box";
import {alpha} from "@mui/system/colorManipulator";
import {Stack} from "@mui/system";
import {AccountButton} from "src/layouts/dashboard/top-nav/buttons/account-button";
import {LanguageSwitchButton} from "src/layouts/dashboard/top-nav/buttons/language-switch-button";
import useMediaQuery from "@mui/material/useMediaQuery";

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

export const TopNav = () => {
  const mdUp = useMediaQuery(theme => theme.breakpoints.down("md"));

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: "blur(100px)",
        backgroundColor: theme => alpha(theme.palette.background.default, 0.8),
        borderBottomColor: theme => alpha(theme.palette.neutral[200], 1),
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        position: "sticky",
        left: 0,
        top: 0,
        width: {
          lg: `calc(100%)`,
        },
        zIndex: theme => theme.zIndex.appBar,
      }}
    >
      <Stack
        flex={1}
        alignItems="center"
        justifyContent="center"
        direction="row"
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{width: "100%"}}
        >
          <img
            src={"/Logo_Small.png"}
            style={{
              width: mdUp ? "72px" : "102px", // 2.27
              height: mdUp ? "32px" : "45px", // 1
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0}>
          <LanguageSwitchButton />
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};
