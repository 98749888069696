const inputTypes = [
  {
    value: "text",
  },
  {
    value: "integer",
  },
  {
    value: "email",
  },
];

function CheckInputType(inputType, response) {
  switch (inputType) {
    case "integer":
      return /^\d+$/.test(response.text);
    default:
      return true;
  }
}

export default function CheckShortTextAnswer(question, answer) {
  if (question.is_required && (answer === undefined || answer.text === "")) {
    return "RequiredQuestionError";
  }
  if (answer !== undefined && answer.text) {
    if (CheckInputType(question.input_type, answer) !== true) {
      const requiredInputType = inputTypes.find(
        inputType => inputType.value === question.input_type,
      );
      return `${requiredInputType.value}InputTypeError`;
    }
  }
  return true;
}
