import {Stack} from "@mui/system";
import {TopNavItem} from "src/layouts/survey-builder/top-nav/top-nav-item";

export const TopNavSection = props => {
  const {items, pathname} = props;

  return (
    <Stack
      component="ul"
      direction="row"
      spacing={0}
      sx={{
        listStyle: "none",
        m: 0,
        p: 0,
      }}
    >
      {items.map(item => {
        const checkPath = !!(item.path && pathname);
        const exactMatch = checkPath ? pathname === item.path : false;

        return (
          <TopNavItem
            active={exactMatch}
            disabled={item.disabled}
            external={item.external}
            icon={item.icon}
            key={item.title}
            label={item.label}
            path={item.path}
            title={item.title}
          />
        );
      })}
    </Stack>
  );
};
