import {useMemo} from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {useTheme} from "@mui/material/styles";

import {LinearProgress, Typography} from "@mui/material";

function LinearProgressWithLabel(props) {
  const percentage = Math.round(props.value);
  return (
    <Box sx={{alignItems: "center"}}>
      <Box sx={{width: "100%", mr: 1}}>
        <LinearProgress
          variant="determinate"
          value={props.isCompleted ? 100 : props.value}
        />
      </Box>
      <Stack sx={{minWidth: 10, alignItems: "center"}}>
        <Typography variant="body2" color="text.secondary">
          {props.isCompleted ? "100%" : `${percentage > 100 ? 100 : percentage}%`}
        </Typography>
      </Stack>
    </Box>
  );
}

const useCssVars = () => {
  const theme = useTheme();

  return useMemo(() => {
    if (theme.palette.mode === "dark") {
      return {
        "--nav-bg": theme.palette.neutral[800],
        "--nav-color": theme.palette.common.white,
        "--nav-divider-color": theme.palette.neutral[700],
        "--nav-border-color": "transparent",
        "--nav-logo-border": theme.palette.neutral[700],
        "--nav-item-color": theme.palette.neutral[400],
        "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-color": theme.palette.common.white,
        "--nav-item-disabled-color": theme.palette.neutral[500],
        "--nav-item-icon-color": theme.palette.neutral[400],
        "--nav-item-icon-active-color": theme.palette.primary.main,
        "--nav-item-icon-disabled-color": theme.palette.neutral[500],
        "--nav-item-chevron-color": theme.palette.neutral[600],
        "--nav-scrollbar-color": theme.palette.neutral[400],
      };
    } else {
      return {
        "--nav-bg": theme.palette.neutral[800],
        "--nav-color": theme.palette.common.white,
        "--nav-divider-color": theme.palette.neutral[700],
        "--nav-border-color": "transparent",
        "--nav-logo-border": theme.palette.neutral[700],
        "--nav-item-color": theme.palette.neutral[400],
        "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-color": theme.palette.common.white,
        "--nav-item-disabled-color": theme.palette.neutral[500],
        "--nav-item-icon-color": theme.palette.neutral[400],
        "--nav-item-icon-active-color": theme.palette.primary.main,
        "--nav-item-icon-disabled-color": theme.palette.neutral[500],
        "--nav-item-chevron-color": theme.palette.neutral[600],
        "--nav-scrollbar-color": theme.palette.neutral[400],
      };
    }
  }, [theme]);
};

export const TopNav = props => {
  const {isCompleted, percentage} = props;
  const cssVars = useCssVars();

  return (
    <Box
      component="header"
      sx={{
        ...cssVars,
        backgroundColor: "white",
        borderBottomColor: "var(--nav-border-color)",
        borderBottomStyle: "solid",
        borderBottomWidth: 5,
        color: "var(--nav-color)",
        left: 0,
        position: "static",
        top: 0,
        zIndex: theme => theme.zIndex.appBar,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="center"
        spacing={2}
        sx={{
          px: 3,
          py: 1,
          width: "100%",
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          <Box width={"150px"}>
            <Stack alignItems="center" pb={1}>
              <img
                className="logo"
                src={"/logo.png"}
                alt="Galata Anket logo"
                width="71"
                height="50"
              />
            </Stack>
            <LinearProgressWithLabel isCompleted={isCompleted} value={percentage * 100} />
          </Box>
        </Stack>
      </Stack>
      <Stack alignItems="flex-end" direction="row"></Stack>
    </Box>
  );
};
