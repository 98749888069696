import {questionType} from "src/schemas/survey/question/question-types";

const multipleChoiceQuestion = question => {
  return {
    title: question.title,
    format: question.format,
    is_required: question.is_required,
    allow_multiple_answers: question.allow_multiple_answers,
    other_option: question.other_option,
    options: question.options,
    carry_options: question.carry_options,
  };
};

export const questionModelFactory = question => {
  switch (question.type) {
    case questionType.MultipleChoice:
      return multipleChoiceQuestion(question);
    case questionType.ShortText:
      return {
        is_required: question.is_required,
        input_type: question.input_type,
      };
  }
};
