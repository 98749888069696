import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Typography} from "@mui/material";
import React from "react";

export const DisabledSurveyPage = props => {
  const {message} = props;

  return (
    <Box sx={{px: "5%"}}>
      <Card>
        <CardContent sx={{textAlign: "center"}}>
          <Typography
            sx={{textAlign: "center"}}
            dangerouslySetInnerHTML={{__html: message}}
          />
        </CardContent>
      </Card>
    </Box>
  );
};
