import {withAuthGuard} from "src/hocs/with-auth-guard";
import {TopNav} from "src/layouts/survey-builder/top-nav";
import {SplashScreen} from "src/components/splash-screen";
import {
  SurveyBuilderConsumer,
  SurveyBuilderProvider,
} from "src/contexts/survey-builder-context";
import styled from "@emotion/styled";

export const SurveyBuilderLayout = withAuthGuard(props => {
  const {children} = props;

  const DashboardContainer = styled.div(_ => ({
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    width: "100%",

    height: "100vh",
    overflow: "auto",
  }));

  const LayoutContainer = styled.div(_ => ({
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    width: "100%",
  }));

  return (
    <DashboardContainer>
      <TopNav />
      <SurveyBuilderProvider>
        <SurveyBuilderConsumer>
          {surveyBuilder => {
            if (surveyBuilder.survey === null) {
              return <SplashScreen />;
            }

            return <LayoutContainer>{children}</LayoutContainer>;
          }}
        </SurveyBuilderConsumer>
      </SurveyBuilderProvider>
    </DashboardContainer>
  );
});
