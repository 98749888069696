import Box from "@mui/material/Box";
import {CardContent, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import React, {useEffect} from "react";
import {useSurveyEngine} from "src/hooks/use-survey-engine";
import SvgIcon from "@mui/material/SvgIcon";
import IconButton from "@mui/material/IconButton";
import Link03Icon from "@untitled-ui/icons-react/build/esm/Link03";
import {paths} from "src/pages/paths";
import {useParams, useSearchParams} from "react-router-dom";
import {useAuth} from "src/hooks/use-auth";

export default function EndPage() {
  const surveyEngine = useSurveyEngine();
  const [surveyParams, setSurveyParams] = useSearchParams();
  const {uuid} = useParams();
  const auth = useAuth();

  const handleCopySurveyUrl = () => {
    const parameterArray = ["share=true"];
    for (let entry of surveyParams.entries()) {
      if (entry[0] !== "share") {
        parameterArray.push(`${entry[0]}=${entry[1]}`);
      }
    }
    const parameters = parameterArray.join("&");
    navigator.clipboard
      .writeText(`${window.location.origin}${paths.survey}/${uuid}?${parameters}`)
      .then(_ => auth.showAlert("success", "Anket URL'i başarıyla kopyalandı!")) // FIXME: use locales
      .catch(_ => auth.showAlert("error", "Survey URL NOT copied!"));
  };

  return (
    <Box sx={{px: "5%"}}>
      <Card>
        <CardContent>
          <Typography
            dangerouslySetInnerHTML={{
              __html: surveyEngine.surveySettings.end_page_message,
            }}
          />
          <Box display="center" justifyContent="center">
            <IconButton onClick={handleCopySurveyUrl} color={"primary"}>
              <SvgIcon>
                <Link03Icon />
              </SvgIcon>
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
