import * as Yup from "yup";
import "dayjs/locale/tr";
import {useFormik} from "formik";
import {useRouter} from "src/hooks/use-router";
import {Stack} from "@mui/system";
import Typography from "@mui/material/Typography";
import {Checkbox, FormControlLabel, Link, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {useParams} from "react-router-dom";
import {DatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import MenuItem from "@mui/material/MenuItem";
import {newauthService} from "src/new_services/auth";
import {paths} from "src/pages/paths";
import {format} from "date-fns";
import Box from "@mui/material/Box";
import {actionService} from "src/new_services/action";
import {SplashScreen} from "src/components/splash-screen";

const initialValues = {
  password: "",
  confirmPassword: "",
  submit: null,
};

const validationSchema = Yup.object({
  password: Yup.string().max(255).required("Bu kısmın doldurulması gereklidir."),
  confirmPassword: Yup.string()
    .max(255)
    .required("Bu kısmın doldurulması gereklidir.")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

export const UserSetPassword = () => {
  const router = useRouter();
  const {setPasswordToken} = useParams();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, helpers) => {
      try {
        setLoading(true);
        const payload = {
          token: setPasswordToken,
          password: values.password,
          confirmPassword: values.confirmPassword,
        };
        newauthService.setPassword(payload).then(response => {
          router.push(paths.auth.login);
          setLoading(false);
          helpers.setSubmitting(false);
        });
      } catch (err) {
        setLoading(false);
        helpers.setSubmitting(false);
        console.error(err);
      }
    },
  });

  return (
    <>
      <Stack sx={{mb: 4}} spacing={1}>
        <Typography variant="h5">Aktivasyon</Typography>
      </Stack>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"tr"}>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            <TextField
              error={!!(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Şifre"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
            />
            <TextField
              error={!!(formik.touched.confirmPassword && formik.errors.confirmPassword)}
              fullWidth
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              label="Şifre Yeniden"
              name="confirmPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.confirmPassword}
            />
          </Stack>
          <Button
            fullWidth
            disabled={formik.isSubmitting}
            sx={{mt: 3}}
            size="large"
            type="submit"
            variant="contained"
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Set Password"}
          </Button>
        </form>
      </LocalizationProvider>
    </>
  );
};
