import axiosInstance from "src/services/axiosInstance";

class SubmissionService {
  async getSubmission(surveyId) {
    return await axiosInstance
      .get(`/surveys/${surveyId}/submission`)
      .then(submission => {
        return submission?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async submitPageAnswers(surveyId, data) {
    return await axiosInstance
      .post(`/surveys/${surveyId}/submit`, data)
      .then(answer => {
        return answer?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async completeSubmission(surveyId) {
    return await axiosInstance
      .post(`/surveys/${surveyId}/complete-submission`)
      .then(answer => {
        return answer?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async deletePreviewSubmission(surveyId) {
    return await axiosInstance
      .delete(`/surveys/${surveyId}/submissions`)
      .then(answer => {
        return answer?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async listSubmissions(surveyId, page) {
    const _page = page ? `?page=${page}` : "";
    return await axiosInstance
      .get(`/surveys/${surveyId}/submissions${_page}`)
      .then(answer => {
        return answer?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async listAllSubmissions(surveyId) {
    return await axiosInstance
      .get(`/surveys/${surveyId}/all-submissions`)
      .then(answer => {
        return answer?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }
}

export const newsubmissionService = new SubmissionService();
