import axiosInstance from "src/services/axiosInstance";

class FlowService {
  async retrieveSurveyFlow(surveyId) {
    return await axiosInstance
      .get(`/surveys/${surveyId}/flow`)
      .then(flow => {
        return flow?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async createFlowElement(survey_id, flowId, data) {
    return await axiosInstance
      .post(`/surveys/${survey_id}/flows/${flowId}/elements`, data)
      .then(flow => {
        return flow?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async dragAndDropElement(survey_id, data) {
    return await axiosInstance
      .post(`/surveys/${survey_id}/flows/elements/drag-and-drop`, data)
      .then(flow => {
        return flow?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async deleteFlowElement(surveyId, flowId, elementId) {
    return await axiosInstance
      .delete(`/surveys/${surveyId}/flows/${flowId}/elements/${elementId}`)
      .then(flow => {
        return flow?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async updateBranchCondition(surveyId, elementId, data) {
    return await axiosInstance
      .post(`/surveys/${surveyId}/flows/elements/${elementId}/branch`, data)
      .then(flow => {
        return flow?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }
}

export const newflowService = new FlowService();
