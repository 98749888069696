import {useState} from "react";
import {useTheme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material/";
import {useMemo} from "react";
import {Drawer, IconButton} from "@mui/material";
import {Stack} from "@mui/system";
import {useTranslation} from "react-i18next";
import {SideNavSection} from "src/layouts/dashboard/side-nav/side-nav-section";
import {useSections} from "src/layouts/dashboard/side-nav/use-sections";
import {useLocation} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {alpha} from "@mui/system/colorManipulator";

const SIDE_NAV_WIDTH = 280;

const useCssVars = () => {
  const theme = useTheme();

  return useMemo(() => {
    if (theme.palette.mode === "dark") {
      return {
        "--nav-bg": theme.palette.neutral[800],
        "--nav-color": theme.palette.common.white,
        "--nav-border-color": "transparent",
        "--nav-logo-border": theme.palette.neutral[700],
        "--nav-section-title-color": theme.palette.neutral[400],
        "--nav-item-color": theme.palette.neutral[400],
        "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-color": theme.palette.common.white,
        "--nav-item-disabled-color": theme.palette.neutral[500],
        "--nav-item-icon-color": theme.palette.neutral[400],
        "--nav-item-icon-active-color": theme.palette.primary.main,
        "--nav-item-icon-disabled-color": theme.palette.neutral[500],
        "--nav-item-chevron-color": theme.palette.neutral[600],
        "--nav-scrollbar-color": theme.palette.neutral[400],
      };
    } else {
      return {
        "--nav-bg": theme.palette.common.white,
        "--nav-color": theme.palette.common.black,
        "--nav-border-color": "transparent",
        "--nav-logo-border": theme.palette.neutral[700],
        "--nav-section-title-color": theme.palette.neutral[400],
        "--nav-item-color": theme.palette.neutral[400],
        "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-color": theme.palette.common.black,
        "--nav-item-disabled-color": theme.palette.neutral[500],
        "--nav-item-icon-color": theme.palette.neutral[400],
        "--nav-item-icon-active-color": theme.palette.primary.main,
        "--nav-item-icon-disabled-color": theme.palette.neutral[500],
        "--nav-item-chevron-color": theme.palette.neutral[600],
        "--nav-scrollbar-color": theme.palette.neutral[400],
      };
    }
  }, [theme]);
};

export const SideNav = () => {
  const sections = useSections();
  const location = useLocation();
  const cssVars = useCssVars();
  const {i18n} = useTranslation();
  const [isOpen, setIsOpen] = useState(false); // State to control the drawer open/close

  const mdUp = useMediaQuery(theme => theme.breakpoints.down("md"));

  const toggleDrawer = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  return (
    <>
      {mdUp && ( // Only show the toggle button on smaller screens
        <IconButton
          onClick={toggleDrawer}
          sx={{
            position: "fixed",
            top: 16,
            left: i18n.language === "ar" ? "auto" : 16,
            right: i18n.language === "ar" ? 16 : "auto",
            zIndex: 1300, // Ensure the button is above the drawer
          }}
        >
          {isOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      )}
      <Drawer
        anchor={i18n.language === "ar" ? "right" : "left"} // Set anchor based on language
        open={!mdUp || isOpen} // Always open on laptops, toggle on smaller screens
        onClose={toggleDrawer} // Close drawer on clicking outside for smaller screens
        PaperProps={{
          sx: {
            ...cssVars,
            backgroundColor: "var(--nav-bg)",
            borderRightColor: theme => alpha(theme.palette.neutral[200], 1),
            borderRightStyle: "solid",
            borderRightWidth: 1,
            color: "var(--nav-color)",
            width: SIDE_NAV_WIDTH,
            zIndex: 2,
          },
        }}
        variant={mdUp ? "temporary" : "permanent"} // Permanent on laptops, temporary on smaller screens
      >
        <Stack sx={{height: "100%"}}>
          <Stack
            component="nav"
            spacing={2}
            sx={{
              flexGrow: 1,
              pt: 2,
            }}
          >
            <SideNavSection items={sections} pathname={location.pathname} />
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};
