import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import {paths} from "src/pages/paths";
import {useParams} from "react-router-dom";

export const useSections = () => {
  const {t, i18n} = useTranslation();
  const {uuid} = useParams();
  const textDirection = i18n.language === "ar" ? "rtl" : "ltr";

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t("sections.survey"),
            path: paths.surveyBuilder.edit.replace(":uuid", uuid),
          },
          {
            title: t("sections.preview"),
            path: paths.surveyBuilder.preview.replace(":uuid", uuid),
          },
          {
            title: t("sections.distributions"),
            path: paths.surveyBuilder.distributions.replace(":uuid", uuid),
          },
          {
            title: t("sections.results"),
            path: paths.surveyBuilder.results.replace(":uuid", uuid),
          },
          {
            title: t("sections.analyticsDashboard"),
            path: paths.surveyBuilder.analyticsDashboard.replace(":uuid", uuid),
          },
        ],
      },
    ];
  }, [t, textDirection]);
};
