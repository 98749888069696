import axiosInstance from "src/services/axiosInstance";

class ActionService {
  async getNotifications(token) {
    return await axiosInstance
      .get(`/user/${token}/notifications`)
      .then(notifications => {
        return notifications?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async updateNotifications(token, payload) {
    return await axiosInstance
      .post(`/user/${token}/notifications`, payload)
      .then(notifications => {
        return notifications?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }
}

export const actionService = new ActionService();
