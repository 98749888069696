import React from "react";
import {Snackbar, Alert as MuiAlert} from "@mui/material";

const GeneralAlert = ({open, onClose, type, message}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <MuiAlert onClose={handleClose} severity={type} sx={{width: "100%"}}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default GeneralAlert;
