import {Container} from "@mui/system";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import {Dialog} from "@mui/material";
import {SlideUpTransition} from "src/components/slide-up-transition";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import {Scrollbar} from "src/components/scrollbar";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import {useEffect, useState} from "react";
import {useRouter} from "src/hooks/use-router";
import {SplashScreen} from "src/components/splash-screen";
import {userService} from "src/services/user";

export const UnsubscribedUsers = () => {
  const [users, setUsers] = useState(null);

  useEffect(() => {
    userService.listUnsubscribedUsers().then(users => {
      console.log(users);
      setUsers(users);
    });
  }, []);

  if (users === null) {
    return <SplashScreen />;
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 1,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={4}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Unsubscribed Users</Typography>
              </Stack>
            </Stack>
            <Card elevation={0}>
              <Scrollbar>
                <Table sx={{minWidth: 1150}}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Email Notifications</TableCell>
                      <TableCell>SMS Notifications</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map(user => (
                      <TableRow key={user.email}>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.phone}</TableCell>
                        <TableCell>{`${user.email_notification_enabled}`}</TableCell>
                        <TableCell>{`${user.sms_notification_enabled}`}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Scrollbar>
            </Card>
          </Stack>
        </Container>
      </Box>
    </>
  );
};
