import {useSurveyEngine} from "src/hooks/use-survey-engine";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Button, Collapse, Grid, Typography} from "@mui/material";
import {QuestionFactory} from "src/pages/new-survey/question-factory";
import {Container} from "@mui/system";
import {LoadingButton} from "@mui/lab";
import {useTranslation} from "react-i18next";
import lodash from "lodash";
import CheckQuestionAnswer from "src/pages/new-survey/check-answers";
import Alert from "@mui/material/Alert";
import {
  displayLogic,
  checkSkipLogic,
} from "src/pages/new-survey/check-question/display-logic";
import EndPage from "src/pages/new-survey/end-page";
import {TopNav} from "src/pages/survey/top-nav";

const checkQuestion = (question, surveyQuestions, surveyAnswers) => {
  if (displayLogic(question, surveyQuestions, surveyAnswers) === false) {
    return false;
  }
  return true;
};

export const Survey = () => {
  const surveyEngine = useSurveyEngine();
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [skipPageQuestionIndex, setSkipPageQuestionIndex] = useState(null);
  const [pageQuestions, setPageQuestions] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const {t} = useTranslation();
  const [pageErrors, setPageErrors] = useState({});
  const [surveyParameters, setSurveyParameters] = useState([]);

  const isSamsungBrowser = navigator.userAgent.includes("SamsungBrowser");

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const uniqueParameters = {};
    for (const [key, value] of queryParameters.entries()) {
      uniqueParameters[key] = value;
    }
    const nameValueParameters = [];
    Object.keys(uniqueParameters).forEach(key =>
      nameValueParameters.push({name: key, value: uniqueParameters[key]}),
    );
    setSurveyParameters(nameValueParameters);
  }, []);

  const checkQuestionAnswers = pageQuestions => {
    const errors = {};
    !surveyEngine.ignoreRequire &&
      pageQuestions.forEach(question => {
        const checkQuestion = CheckQuestionAnswer(
          question,
          surveyEngine.surveyAnswers[question.id],
        );
        if (checkQuestion !== true) {
          errors[question.id] = checkQuestion;
        }
      });
    setPageErrors(errors);
    return lodash.isEmpty(errors);
  };

  const handleNextButton = _ => {
    setIsLoading(true);
    if (checkQuestionAnswers(pageQuestions)) {
      const pageId = surveyEngine.blockQuestions.results[pageIndex].page_id;
      surveyEngine
        .handlePageAnswers(pageId, pageQuestions.length, surveyParameters)
        .then(_ => {
          let skipPageIndex = null;
          for (const pageQuestion of pageQuestions) {
            let skipLogic = checkSkipLogic(
              pageQuestion,
              surveyEngine.surveyQuestions,
              surveyEngine.surveyAnswers,
            );
            if (skipLogic) {
              if (skipLogic.type === "end_of_survey") {
                surveyEngine.handleCompleteSubmission();
                setIsEnd(true);
                setIsLoading(false);
              } else if (skipLogic.type === "end_of_block") {
                surveyEngine
                  .handleGetNextBlock(surveyEngine.blockQuestions.next_flow_element_id)
                  .then(_ => {
                    setPageIndex(0);
                    setIsLoading(false);
                  });
              } else if (skipLogic.type === "question") {
                for (
                  let i = pageIndex + 1;
                  i < surveyEngine.blockQuestions.results.length;
                  i++
                ) {
                  const tempPageQuestions = handlePageQuestions(i);
                  const questionIndex = tempPageQuestions.findIndex(
                    qu => qu.id === skipLogic.destination_question_id,
                  );
                  if (questionIndex > -1) {
                    skipPageIndex = i;
                    setSkipPageQuestionIndex(questionIndex);
                    break;
                  }
                }
              }
            }
          }
          if (skipPageIndex) {
            setPageIndex(skipPageIndex);
            setIsLoading(false);
          } else if (pageIndex === surveyEngine.blockQuestions.results.length - 1) {
            if (surveyEngine.blockQuestions.next_flow_element_id === null) {
              surveyEngine.handleCompleteSubmission();
              setIsEnd(true);
              setIsLoading(false);
            } else {
              surveyEngine
                .handleGetNextBlock(surveyEngine.blockQuestions.next_flow_element_id)
                .then(_ => {
                  setPageIndex(0);
                  setIsLoading(false);
                });
            }
          } else {
            setPageIndex(pageIndex + 1);
            setIsLoading(false);
          }
        });
    } else {
      setIsLoading(false);
    }
  };

  const handlePageQuestions = pageIndex => {
    const currentPageQuestions = surveyEngine.blockQuestions.results[
      pageIndex
    ].questions.filter(question =>
      checkQuestion(question, surveyEngine.surveyQuestions, surveyEngine.surveyAnswers),
    );
    if (currentPageQuestions.length === 0) {
      handleNextButton();
    }
    return currentPageQuestions;
  };

  useEffect(() => {
    if (surveyEngine.blockQuestions) {
      if (surveyEngine.blockQuestions.results.length === 0) {
        surveyEngine.handleCompleteSubmission();
        setIsEnd(true);
      } else {
        if (surveyEngine.blockQuestions.results.length > 0) {
          let currPageQuestions = handlePageQuestions(pageIndex);
          if (skipPageQuestionIndex) {
            currPageQuestions = currPageQuestions.slice(skipPageQuestionIndex);
            setSkipPageQuestionIndex(null);
          }
          setPageQuestions(currPageQuestions);
        }
      }
    }
  }, [surveyEngine.blockQuestions, pageIndex]);

  if (pageQuestions === null) {
    return <>Loading</>;
  }

  if (isEnd || surveyEngine.isCompleted) {
    return <EndPage />;
  }

  return (
    <Box translate={"no"}>
      <TopNav
        isCompleted={surveyEngine.isCompleted}
        percentage={
          !surveyEngine.remainingQuestionCount
            ? 0
            : (surveyEngine.totalQuestionCount - surveyEngine.remainingQuestionCount) /
              surveyEngine.totalQuestionCount
        }
      />

      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            pb: isSamsungBrowser ? 12 : 9,
          }}
        >
          {pageQuestions.map(question => (
            <Box key={question.id} sx={{my: 4}}>
              <Typography
                textAlign={"justify"}
                dangerouslySetInnerHTML={{__html: question.text}}
              />
              <Collapse in={pageErrors[question.id] && pageErrors[question.id] !== true}>
                <Alert severity="error">
                  <strong>{t(pageErrors[question.id])}</strong>
                </Alert>
              </Collapse>
              <QuestionFactory question={question} />
            </Box>
          ))}

          <Grid container justifyContent="flex-end">
            {/*{surveyEngine.surveySettings.show_previous_button && (*/}
            {/*  <Button*/}
            {/*    variant="contained"*/}
            {/*    sx={{mx: 2}}*/}
            {/*    // disabled={survey.currentSurveyPageIndex === 0}*/}
            {/*    disabled={true}*/}
            {/*    onClick={handlePrevButton}*/}
            {/*  >*/}
            {/*    {t("prev")}*/}
            {/*  </Button>*/}
            {/*)}*/}
            <LoadingButton
              loading={isLoading}
              variant="contained"
              onClick={handleNextButton}
            >
              {t("next")}
            </LoadingButton>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
