import axiosInstance from "src/services/axiosInstance";

class SurveyService {
  async listSurveys(page) {
    return await axiosInstance
      .get(`/surveys`, {
        params: {
          page: page,
        },
      })
      .then(surveys => {
        return surveys?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async createDefaultSurvey(title) {
    const data = {
      title: title,
    };

    return await axiosInstance
      .post(`/surveys`, data)
      .then(survey => {
        return survey?.data?.id;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async retrieveSurvey(uuid) {
    return await axiosInstance
      .get(`/surveys/${uuid}`)
      .then(survey => {
        // return survey?.data?.results;
        return survey?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async listSurveyQuestions(surveyId, element) {
    return await axiosInstance
      .get(
        `/surveys/${surveyId}/questions/preview${element ? `?element=${element}` : ""}`,
      )
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async copySurvey(uuid) {
    return await axiosInstance
      .post(`/surveys/${uuid}/copy`)
      .then(survey => {
        // return survey?.data?.results;
        return survey;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async updateSurveySettings(uuid, payload) {
    return await axiosInstance
      .patch(`/surveys/${uuid}/settings`, payload)
      .then(survey => {
        return survey?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async deleteSurvey(id) {
    return await axiosInstance
      .delete(`/surveys/${id}`)
      .then(survey => {
        // return survey?.data?.results;
        return survey;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async createFromExcel(payload) {
    return await axiosInstance
      .post(`/surveys/excel`, payload)
      .then(survey => {
        return survey?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }
}

export const newsurveyService = new SurveyService();
