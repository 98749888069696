import axiosInstance from "src/services/axiosInstance";

class UserService {
  async listUnsubscribedUsers() {
    return await axiosInstance
      .get(`/user/unsubscribed`)
      .then(users => {
        return users?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }
}

export const userService = new UserService();
