import React, { useState } from 'react';
import { Container } from "@mui/system";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@mui/material/useMediaQuery";

export const FindParticipants = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [selectedSurvey, setSelectedSurvey] = useState('');
  const [participants, setParticipants] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState([]);
  const [projectDetails, setProjectDetails] = useState('');
  const [submittedRequests, setSubmittedRequests] = useState([]);
  const [participantsError, setParticipantsError] = useState('');

  const handleSurveyChange = (event) => {
    setSelectedSurvey(event.target.value);
  };

  const handleGenderClick = (selectedGender) => {
    setGender(selectedGender);
  };

  const handleAgeClick = (selectedAge) => {
    setAge((prevAges) => {
      if (prevAges.includes(selectedAge)) {
        return prevAges.filter((age) => age !== selectedAge);
      } else {
        return [...prevAges, selectedAge];
      }
    });
  };

  const handleParticipantsChange = (event) => {
    const value = event.target.value;
    setParticipants(value); // Always set the input value to make it visible
    if (/^\d*$/.test(value)) {
      setParticipantsError(''); // Clear the error if the value is valid
    } else {
      setParticipantsError('Please enter a valid number'); // Set an error message for invalid input
    }
  };

  const handleSubmit = () => {
    const newRequest = {
      survey: selectedSurvey,
      participants,
      gender,
      age: age.join(', '), // Join multiple ages with a comma
      projectDetails,
      status: 'Pending',
    };
    setSubmittedRequests([...submittedRequests, newRequest]);
    // Clear the form fields after submission
    setSelectedSurvey('');
    setParticipants('');
    setGender('');
    setAge([]);
    setProjectDetails('');
  };

  const isFormValid = () => {
    return selectedSurvey && participants && !participantsError && gender && age.length > 0 && projectDetails;
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth="md">
          <Stack spacing={4} alignItems="center">
            {/* Step Instructions */}
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>Find Participants</Typography>
            <Stack direction={isMobile ? "column" : "row"} spacing={4} alignItems="center" justifyContent="center">
              <Box textAlign="center">
                <Typography variant="h6">01</Typography>
                <Typography variant="body1">Select the survey you want to distribute</Typography>
              </Box>
              <Box textAlign="center">
                <Typography variant="h6">02</Typography>
                <Typography variant="body1">Select Participant Demographics</Typography>
              </Box>
              <Box textAlign="center">
                <Typography variant="h6">03</Typography>
                <Typography variant="body1">Send to an agent to get a quote</Typography>
              </Box>
            </Stack>

            {/* Survey Selection */}
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select the survey</InputLabel>
              <Select
                value={selectedSurvey}
                onChange={handleSurveyChange}
                label="Select the survey"
              >
                <MenuItem value="Survey 1">Survey 1</MenuItem>
                <MenuItem value="Survey 2">Survey 2</MenuItem>
                <MenuItem value="Survey 3">Survey 3</MenuItem>
              </Select>
            </FormControl>

            {/* Number of Participants */}
            <TextField
              fullWidth
              label="Number of Participants"
              variant="outlined"
              value={participants}
              onChange={handleParticipantsChange}
              error={!!participantsError}
              helperText={participantsError}
            />

            {/* Gender Selection */}
            <Box width="100%" textAlign="center">
              <Typography variant="h6" sx={{ mb: 2 }}>Select Gender</Typography>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  variant={gender === 'All' ? "contained" : "outlined"}
                  onClick={() => handleGenderClick('All')}
                >
                  All
                </Button>
                <Button
                  variant={gender === 'male' ? "contained" : "outlined"}
                  onClick={() => handleGenderClick('male')}
                >
                  Male
                </Button>
                <Button
                  variant={gender === 'female' ? "contained" : "outlined"}
                  onClick={() => handleGenderClick('female')}
                >
                  Female
                </Button>
                
              </Stack>
            </Box>

            {/* Age Selection */}
            <Box width="100%" textAlign="center" mt={3}>
              <Typography variant="h6" sx={{ mb: 2 }}>Select Age Group(s)</Typography>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  variant={age.includes('18-24') ? "contained" : "outlined"}
                  onClick={() => handleAgeClick('18-24')}
                >
                  18-24
                </Button>
                <Button
                  variant={age.includes('25-34') ? "contained" : "outlined"}
                  onClick={() => handleAgeClick('25-34')}
                >
                  25-34
                </Button>
                <Button
                  variant={age.includes('35-44') ? "contained" : "outlined"}
                  onClick={() => handleAgeClick('35-44')}
                >
                  35-44
                </Button>
                <Button
                  variant={age.includes('45+') ? "contained" : "outlined"}
                  onClick={() => handleAgeClick('45+')}
                >
                  45+
                </Button>
              </Stack>
            </Box>

            {/* Project Details */}
            <TextField
              fullWidth
              label="Project Details"
              multiline
              rows={4}
              variant="outlined"
              value={projectDetails}
              onChange={(e) => setProjectDetails(e.target.value)}
              sx={{ mt: 3 }}
            />

            {/* Submit Button */}
            <Button
              variant="contained"
              size="large"
              sx={{ backgroundColor: "#ad1445", color: "white", mt: 3 }}
              onClick={handleSubmit}
              disabled={!isFormValid()} // Disable button if form is not valid
            >
              Submit
            </Button>

            {/* Submitted Requests Table */}
            <Box width="100%" mt={4}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Submitted Requests</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Survey</TableCell>
                    <TableCell>Participants</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Age</TableCell>
                    <TableCell>Project Details</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {submittedRequests.length > 0 ? (
                    submittedRequests.map((request, index) => (
                      <TableRow key={index}>
                        <TableCell>{request.survey}</TableCell>
                        <TableCell>{request.participants}</TableCell>
                        <TableCell>{request.gender}</TableCell>
                        <TableCell>{request.age}</TableCell>
                        <TableCell>{request.projectDetails}</TableCell>
                        <TableCell>{request.status}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No submitted requests yet.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
};
