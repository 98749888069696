export const multipleChoiceQuestionSettings = {
  format: "format",
  allowMultipleAnswers: "allow_multiple_answers",
  options: "multiplechoiceoption_set",
};

export const multipleChoiceQuestionFormat = {
  list: "list",
  dropdown: "dropdown",
};

export const multipleChoiceQuestionFormatMap = [
  {
    label: "List",
    value: multipleChoiceQuestionFormat.list,
  },
  {
    label: "Dropdown",
    value: multipleChoiceQuestionFormat.dropdown,
  },
];
