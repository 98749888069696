export const enTranslations = {
  mySurveys: "My Surveys",
  tableHeaders: {
    title: "Title",
    questions: "Questions",
    responses: "Responses",
    completion: "Completion",
    created: "Created",
    updated: "Updated",
    actions: "Actions",
  },
  prev: "Previous Button",
  next: "Next Button",
  noDataFound: "No data found",
  settings: {
    surveyTitle: "Survey Title",
    live: "Live",
  },
  buttons: {
    downloadDraftExcel: "Draft Excel File",
    UploadDraftExcel: "Edited Excel File",
    yourDraftExcel: "Your Excel File",
  },
  importExcelButtonLabel: "Excel File",
  importExcelDialogTitle: "Survey From Excel File",
  importExcelNameLabel: "Survey Name",
  importFileButtonLabel: "Import File",
  createSurveyButtonLabel: "Create",
  createSurveyDialogTitle: "Create a survey from scratch",
  nameInputLabel: "Name",
  createButtonLabel: "Create",
  cancelButtonLabel: "Back",
  copyLink: "Copy Link",
  copySurvey: "Copy Survey",
  delete: "Delete",
  deleteSurveyTitle: "Delete Survey",
  DeleteSurveyConfirmation:
    "{{surveyTitle}} survey will be deleted permanently. Are you sure?",

  cancel: "Cancel",
  sections: {
    survey: "Survey",
    preview: "Preview",
    distributions: "Distributions",
    results: "Results",
    analyticsDashboard: "Analytics Dashboard",
  },
  basicSettings: {
    title: "Basic Settings",
    language: "Language",
    languageDescription:
      "We recommend selecting the language you wrote your survey in. Your response data will also be displayed in this language.",
    previousButton: "Previous Button",
    previousButtonDescription: "Enable/Disable previous button.",
    ipBlock: "IP Block",
    ipBlockDescription: "Block multiple responses from the same IP.",
  },

  writeSomething: "Write something",
  unavailableSurveyMessage: "Unavailable Survey Message",
  buttonLabels: {
    update: "Update",
    reset: "Reset",
  },
  blockSettings: {
    editBlock: "Edit Block",
    displayLogicWarning:
      "Attempting to use display logic and randomization within the same block. This combination may not function as expected.",
    randomizeQuestions: "Randomize Questions",
    addPageBreak: "Add Page Break to all Questions",
    makeAllRequired: "Make All Questions Required",
  },
  editor: {
    endPageLabel: "End Page",
    createBlockTooltip: "Create Question Block",
    editBlockTooltip: "Edit Question Block",
    deleteBlockTooltip: "Delete Question Block",
    blockDeletionConfirmation: "block will be permanently deleted. Are you sure?",
  },

  downloadResults: "Download Results",
  resetSurvey: "Reset Survey",
  questionLabel: "Question Label",
  duplicate: "Duplicate",
  shuffleQuestion: "Shuffle Question",
  deleteQuestion: "Delete Question",
  ignoreRequire: "Ignore Require",
  ignorePreviewData: "Ignore Preview Data",
  addOption: "Add Option",

  label: "Default Question Label",
  choice1: "Choice 1",
  choice2: "Choice 2",
  choice3: "Choice 3",
  defaultQuestion: "Default Question Title",
  save: "Save",
  reset: "Reset",

  EditQuestion: "Edit Question",
  QuestionType: "Question Type",
  Text: "Text",
  Settings: "Settings",
  Behaviours: "Behaviours",
  MultipleChoice: "Multiple Choice",
  ShortText: "Short Text",
  TextGraphic: "Text / Graphic",
  SurveyQuestion: "Survey Question",
  EmbeddedDataField: "Embedded Data Field",
  EditQuestionTextDialogTitle: "Edit Question Text",
  PlaceholderWriteSomething: "Write something",
  CancelButton: "Cancel",

  SaveButton: "Save",
  RequiredLabel: "Required",
  ContentTypeLabel: "Content Type",
  TextLabel: "Text",
  NumberLabel: "Number",
  EmailAddressLabel: "Email Address",
  RequiredQuestionError: "This question is required.",
  textInputTypeError: "Text input is invalid.",
  integerInputTypeError: "Number input is invalid.",
  emailInputTypeError: "Email input is invalid.",
  DisplayLogic: "Display Logic",
  DownloadQRCode: "Download QR Code",
  CopyLink: "Copy Link",
  AddElement: "Add Element",
  Link: "Link",
  QRCode: "QR Code",
  CreateQuestion: "Create Question",
  AddPageBreak: "+ Add Page Break",
  LogoutButton: "Logout",
  AddBlock: "Add Block",
  AddBranch: "Add Branch",
  successfullyUpdated: "Successfully Updated",

  builder: "Builder",
  surveyFlow: "Survey flow",
  surveyOptions: "Survey options",
  design: "Design",

  auth: {
    login: "Log in",
    register: "Register",
    resetPassword: {
      title: "Reset Password",
      textLabel: "Email Address or Phone Number",
      buttonLabel: "Send activation link",
      invalidInputMessage: "Please provide a valid email or phone number (05329993377).",
      confirmationMessage:
        "If your info is in the system, you'll get an activation email/SMS. Check your email or phone. For questions, contact katilimcihavuzu@galataanket.com.",
    },
    forgotpasswordtitle: "Renew Password",
    forgotpasswordlink: "Sent a new password",
    emailAddress: "Email or phone number",
    password: "Password",
    Login: "Login",
    continue: "Send activation link",
    sendnewpassword: "Send a new password",
    sentinfo:
      "If your info isn't in the system, you'll get an activation email/SMS. Check your email or phone. For questions, contact katilimcihavuzu@galataanket.com.",
    passwordsentinfo:
      "If your info isn't in the system, you'll get a new password. You can change it from account settings again. For questions, contact katilimcihavuzu@galataanket.com.",
    forgotPassword: "Forgot password?",
    noAccount: "Don't have an account?",
  },
  designTab: {
    logo: "Logo",
    general: "General",
    background: "Background",
    style: "Style",
    motion: "Motion",
    similars: {
      left: "Left",
      center: "Center",
      right: "Right",
      top: "Top",
      bottom: "Bottom",
    },
    logoStyles: {
      logoImage: "Logo Image",
      change: "Change",
      placement: "Placement",
      verticalPlacement: "Vertical Placement",
      maxHeight: "Max Height",
      maxWidth: "Max Width",
    },
    generalStyles: {
      nextButtonText: "Next Button Text",
      previousButtonText: "Previous Button Text",
      progressBar: "Progress Bar",
      none: "None",
      withText: "With Text",
      withoutText: "Without Text",
      progressBarPlacement: "Progress Bar Placement",
      progressBarVerticalPlacement: "Progress Bar Vertical Placement",
    },
    styleStyles: {
      primaryColor: "Primary Color",
      secondaryColor: "Secondary Color",
      font: "Font",
      questionFontSize: "Question Font Size (px)",
      answersFontSize: "Answers Font Size (px)",
    },
    backgroundStyles: {
      backgroundColor: "Background Color",
    },
    motionStyles: {
      animationType: "Animation Type",
      slide: "Slide",
      fade: "Fade",
      grow: "Grow",
      zoom: "Zoom",
      collapse: "Collapse",
      slideAnimationDirection: "Slide Animation Direction",
    },
    previewSide: {
      mySurvey: "My Survey",
      sampleSurvey: "Sample Survey",
      viewDesktop: "View Desktop",
      viewMobile: "View Mobile",
      selectSurvey: "Select Survey",
    },
  },
};
