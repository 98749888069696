import {FormControl, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useEffect, useState} from "react";
import {useSurveyEngine} from "src/hooks/use-survey-engine";

export const DropdownOptions = props => {
  const {question, options} = props;
  const [selectedOption, setSelectedOption] = useState("");
  const surveyEngine = useSurveyEngine();

  useEffect(() => {
    const prevAnswer = surveyEngine.surveyAnswers[question.id];
    if (prevAnswer) {
      setSelectedOption(prevAnswer.text);
    }
  }, []);

  const handleAnswer = event => {
    const answerId = event.target.value;
    setSelectedOption(answerId);
    const answerText = options.find(opt => opt.id === answerId).text;
    surveyEngine.handleQuestionAnswer(question, answerText);
  };

  return (
    <FormControl sx={{mt: 1, minWidth: 200}}>
      <Select value={selectedOption} onChange={handleAnswer} displayEmpty={true}>
        {!question.is_required && (
          <MenuItem value="">
            <em style={{color: "transparent"}}>None</em>
          </MenuItem>
        )}
        {options.map(opt => (
          <MenuItem key={opt.id} name={opt.id} value={opt.id}>
            {opt.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
