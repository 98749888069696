import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {enTranslations} from "src/locales/translations/en";
import {trTranslations} from "src/locales/translations/tr";
import {arTranslations} from "src/locales/translations/ar";
import LanguageDetector from "i18next-browser-languagedetector";

// Load language preference from local storage
const savedLanguage = localStorage.getItem("language");

// Use saved language if available, otherwise fallback to 'en'
const defaultLanguage = savedLanguage || "en";

i18n
  .use(LanguageDetector) // Use the language detector
  .use(initReactI18next)
  .init({
    lng: defaultLanguage, // Set the default language
    fallbackLng: "en", // Fallback language if no language is detected or supported
    resources: {
      en: {
        translation: enTranslations,
      },
      tr: {
        translation: trTranslations,
      },
      ar: {
        translation: arTranslations,
      },
    },
  });
