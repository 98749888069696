import axiosInstance from "src/services/axiosInstance";

class QuestionService {
  async updateQuestion(questionId, payload) {
    return await axiosInstance
      .patch(`/questions/${questionId}`, payload)
      .then(question => {
        return question?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async createQuestionDisplayLogic(questionId, payload) {
    return await axiosInstance
      .post(`/questions/${questionId}/display-logics`, payload)
      .then(question => {
        return question?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async updateQuestionType(questionId, payload) {
    return await axiosInstance
      .patch(`/questions/${questionId}/type`, payload)
      .then(question => {
        return question?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async createMultipleChoiceQuestionOptions(questionId) {
    return await axiosInstance
      .post(`/multiple-choice-questions/${questionId}/options`)
      .then(question => {
        return question?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async updateMultipleChoiceQuestionOptions(questionId, data) {
    return await axiosInstance
      .put(`/multiple-choice-questions/${questionId}/options`, data)
      .then(question => {
        return question?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async deleteMultipleChoiceQuestionOptions(questionId, optionId) {
    return await axiosInstance
      .delete(`/multiple-choice-questions/${questionId}/options/${optionId}`)
      .then(question => {
        return question?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async deleteMultipleChoiceQuestionCarryOptions(questionId) {
    return await axiosInstance
      .delete(`/multiple-choice-questions/${questionId}/carry-options`)
      .then(question => {
        return question?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async createQuestionSkipLogic(questionId, payload) {
    return await axiosInstance
      .post(`/questions/${questionId}/skip-logics`, payload)
      .then(question => {
        return question?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async deleteQuestionSkipLogic(questionId, skipLogicId) {
    return await axiosInstance
      .delete(`/questions/${questionId}/skip-logics/${skipLogicId}`)
      .then(question => {
        return question?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }
}

export const newquestionService = new QuestionService();
