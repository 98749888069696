import { Container } from "@mui/system";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { ImportExcelButton } from "src/pages/admin/surveys/buttons/import-excel-button";
import { CreateSurveyButton } from "src/pages/admin/surveys/buttons/create-survey-button";
import useMediaQuery from "@mui/material/useMediaQuery";

// Mock survey data (replace this with real data fetching logic)
const surveyData = [
  { title: "Demographic Poll", description: "Using this draft you can then customize it." },
  { title: "Customer Feedback", description: "Using this draft you can then customize it." },
  { title: "Employee Feedback", description: "Using this draft you can then customize it." },
];

const fromScratchData = [
  { title: "New survey from scratch", description: "Start from Scratch", fromScratch: true },
  { title: "New survey from excel sheet", description: "Upload from an excel sheet", fromExcel: true },
];

export const NewSurvey = () => {
  const [surveys, setSurveys] = useState(null);
  const [fromScratch, setFromScratch] = useState(null);

  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    // Simulate data fetching
    setTimeout(() => {
      setSurveys(surveyData);
      setFromScratch(fromScratchData);
    }, 1000);
  }, []);

  if (surveys === null || fromScratch === null) {
    return <Typography>Loading...</Typography>; // Simple loading state
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={4}>
            {/* Most Popular Templates Section */}
            <Typography variant="h4">Create a new survey</Typography>
            <Typography variant="h6">Start from scratch or an excel sheet</Typography>
            <Box display="flex" justifyContent="center" flexWrap={isMobile ? "nowrap" : "wrap"} flexDirection={isMobile ? "column" : "row"} gap={2}>
              {fromScratch.map((survey, index) => (
                <Card
                  key={index}
                  sx={{
                    width: isMobile ? "100%" : "30%",
                    height: "150px",
                    padding: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    position: "relative",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "box-shadow 0.3s ease-in-out",
                    border: "2px solid #333",
                    "&:hover": {
                      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                      borderColor: "#ad1445",
                    },
                  }}
                >
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      {survey.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {survey.description}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "absolute",
                      bottom: 8,
                      left: 8,
                      right: 8,
                    }}
                  >
                    <img
                      src="/favicon.ico"
                      alt="Logo"
                      style={{ width: 40, height: 40 }}
                    />
                    {survey.fromScratch ? (
                      <CreateSurveyButton />
                    ) : (
                      <ImportExcelButton />
                    )}
                  </Box>
                </Card>
              ))}
            </Box>

            {/* From Scratch Section */}
            <Typography variant="h6">Select From Most Popular Templates</Typography>
            <Box display="flex" justifyContent="center" flexWrap={isMobile ? "nowrap" : "wrap"} flexDirection={isMobile ? "column" : "row"} gap={2}>
              {surveys.map((survey, index) => (
                <Card
                  key={index}
                  sx={{
                    width: isMobile ? "100%" : "20%",
                    height: "200px",
                    padding: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    position: "relative",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "box-shadow 0.3s ease-in-out",
                    border: "2px solid #000",
                    "&:hover": {
                      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                      borderColor: "#ad1445",
                    },
                  }}
                >
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      {survey.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {survey.description}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "absolute",
                      bottom: 8,
                      left: 8,
                      right: 8,
                    }}
                  >
                    <img
                      src="/favicon.ico"
                      alt="Logo"
                      style={{ width: 40, height: 40 }}
                    />
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "black",
                        },
                      }}
                    >
                      Start
                    </Button>
                  </Box>
                </Card>
              ))}
            </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
};
